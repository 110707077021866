import { useState, Dispatch, SetStateAction } from "react";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { IProjectSummaryDto } from "../../../../interfaces/DTO/IProjectSummaryDto";
import { useTranslation } from "react-i18next";
import { EProjectStatusDescription } from "../../../../enum/EProjectStatus";
import "../ProjectSummary.css";
import { IProductDesignSummaryResponse } from "../../../../interfaces/Project/Response/ProductDesignSummaryResponse";
import { IProductDesignSummaryDto } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import { Card } from "../Card/Card";
import useProjectHandler from "../../../../hooks/useProjectHandler";
import { useStoreState } from "../../../../hooks";

export function Row(props: {
  row: IProjectSummaryDto;
  projects: IProductDesignSummaryResponse[];
  productDesignSummary: IProductDesignSummaryDto[];
  setCoverCost: Dispatch<SetStateAction<number>>;
  refeshScreen: () => void;
  projectId: string;
}) {
  const { isProjectDocumentGenerated } = useStoreState((state) => state.app);
  const { handleDeleteProject } = useProjectHandler();
  const { row, projects, setCoverCost, productDesignSummary, refeshScreen, projectId } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "#fafafa", height: "125px" }}>
        <TableCell
          component="th"
          scope="row"
          className="summaryTable_Cell_text details"
        >
          <IconButton
            aria-label="expand row"
            sx={{ height: 16, width: 16 }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon sx={{ color: "black" }} /> : <KeyboardArrowDownIcon sx={{ color: "black" }} />}
          </IconButton>
          <Typography
            component="div"
            sx={{ display: "inline-flex", paddingLeft: "16px" }}
            fontWeight={700}
          >
            {" "}
            {row.name}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className="summaryTable_Cell_text"
        >
          {row.productDesignsQuantity}
        </TableCell>
        <TableCell
          align="left"
          className="summaryTable_Cell_text"
        >
          {t(`${EProjectStatusDescription.get(row.status)}`)}
        </TableCell>
        <TableCell
          align="center"
          className="summaryTable_Cell_text"
        >
        {!isProjectDocumentGenerated && !row.isPdfGenerated && !row.isXlsGenerated &&
          <IconButton onClick={() => handleDeleteProject(row)}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ padding: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box>
              <Table>
                <TableBody>
                  <Card
                    projectSummary={row}
                    projects={projects}
                    setCoverCost={setCoverCost}
                    productDesignSummary={productDesignSummary}
                    refeshScreen={refeshScreen}
                    projectId={projectId}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
