import { useStoreActions, useStoreState } from "../hooks";
import { useEffect, useState } from "react";
import { IFoundFavoriteDesigns } from "../interfaces/IFoundFavoriteDesigns";

const useCheckFavoriteDesigns = () => {
  const { productDesigns } = useStoreState((state) => state.designs.data);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);

  const { thunkCheckFavoriteDesigns, thunkGetProductDesignDataByProjectId } = useStoreActions(
    (actions) => actions.designs
  );

  const [checkedFavoriteDesigns, setCheckedFavoriteDesigns] = useState<IFoundFavoriteDesigns[]>([]);

  useEffect(() => {
    thunkGetProductDesignDataByProjectId(currentProjectId!);
  }, []);

  useEffect(() => {
    if (productDesigns.length === 0 || checkedFavoriteDesigns.length > 0) {
      return;
    }

    const checkInitial = async () => {
      try {
        const response = await thunkCheckFavoriteDesigns();
        setCheckedFavoriteDesigns(response);
      } catch (error) {
        console.error("Error during thunk execution:", error);
      }
    };

    checkInitial();
  }, [productDesigns]);

  return {
    checkedFavoriteDesigns,
    setCheckedFavoriteDesigns,
  };
};

export default useCheckFavoriteDesigns;
